<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="250px">
      <el-form-item label="所属父级">
        <SelectGuid width="250px" v-model="formData.pid"></SelectGuid>
      </el-form-item>
      <el-form-item label="标题名称">
        <el-input style="width: 250px" size="mini" v-model="formData.title" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="" size="small" @click="$router.back()">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import SelectGuid from '@/views/biaoxun/guide/select-guide.vue'

export default {
  name: 'Update',
  components: { SelectGuid },
  data() {
    return {
      formData: {
        title: '',
        pid: 0,
      },
      pidArr: []
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  methods: {
    convertToArr(val) {
      // 如果 val 是一个数字或字符串，则将其转换为数组
      if (typeof val === 'number' || typeof val === 'string') {
        return [val];
      }
      // 如果 val 已经是一个数组，则直接返回
      if (Array.isArray(val)) {
        return val;
      }
      // 如果 val 为空或 undefined，则返回一个空数组
      return [];
    },
    save () {
      if (this.pidArr.length > 0) {
          this.formData.pid = this.pidArr[0] || 0
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail () {
      this.formData = await getDetailAPI(this.$route.params.id)
      this.pidArr = this.convertToArr(this.formData.pid)
    }
  }
}
</script>
