<template>
  <el-cascader
    :style="{ width: width }"
    size="small"
    :options="list"
    :show-all-levels="false"
    :props="cascProps"
    :disabled="disabled"
    filterable
    clearable
    placeholder="请选择"
    v-model="selectValue"
  ></el-cascader>
</template>

<script>
import { getTreeAPI } from './api'

export default {
  name: 'SelectGuide',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '250px'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    show_update: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    selectValue: {
      get () {
        return this.value
      },

      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    selectValue: {
      handler (newV, oldV) {
        this.getTreeList(this.list, this.selectValue)
      }
    },
    show_update: {
      handler (newV, oldV) {
        this.getGuideTree()
      }
    }
  },

  data () {
    return {
      list: [],
      cascProps: {
        label: 'title',
        value: 'id',
        checkStrictly: true,
        expandTrigger: 'hover',
        emitPath: false,
        multiple: false,
        leaf: 'leaf',
        children: 'children'
      }
    }
  },

  created () {
    this.getGuideTree()
  },
  mounted () {
    this.getGuideTree()
  },
  methods: {
    async getGuideTree () {
      let res = await getTreeAPI()
      this.list = this.setLeaf(res)
    },

    setLeaf (list) {
      return list.map((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.setLeaf(item.children)
        } else {
          item.leaf = true
          delete item.children
        }
        return item
      })
    },
    getTreeList (list, id) {
      list.forEach((item) => {
        if (item.id == id) {
          this.$emit('update:city', item.title)
        } else if (item.children && item.children.length > 0) {
          this.getTreeList(item.children, id)
        }
      })
    }
  }
}
</script>
<style></style>
